import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import DevexHighOrderComponent from '../devex_high_order_component/devex_high_order_component';
import styles from './alert_handler.module.scss';

const messagePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.object]);

const ErrorAlert = ({ message, closeAlert }) => {
  // This alert is using global styles defined in app/assets/stylesheets/ui_toolbox/_alerts.scss
  return (
    <div className='alert-wrap'>
      <div className='alert alert-danger z-depth-1 clearfix' role='alert' aria-label='danger alert'>
        <div className='text-content'>
          <i className='fal icon icon-times-circle' />
          <span className='text'>{message}</span>
        </div>
        <button
          className={`close ${styles.alertCloseButton}`}
          onClick={closeAlert}
          aria-label='close danger alert'
        >
          <i className='fal icon-times' />
        </button>
      </div>
    </div>
  );
};

ErrorAlert.defaultProps = {
  message: 'Something went wrong. Please try again.',
  closeAlert: () => null,
};

ErrorAlert.propTypes = {
  message: messagePropType,
  closeAlert: PropTypes.func,
};

const SuccessAlert = ({ message, closeAlert }) => {
  // This alert is using global styles defined in app/assets/stylesheets/ui_toolbox/_alerts.scss
  return (
    <div className='alert-wrap'>
      <div
        className='alert alert-success z-depth-1 clearfix'
        role='alert'
        aria-label='success alert'
      >
        <div className='text-content'>
          <i className='fal icon icon-check' />
          <span className='text'>{message}</span>
        </div>
        <button
          className={`close ${styles.alertCloseButton}`}
          onClick={closeAlert}
          aria-label='close success alert'
        >
          <i className='fal icon-times' />
        </button>
      </div>
    </div>
  );
};

SuccessAlert.defaultProps = {
  message: 'Action was successfully executed',
  closeAlert: () => null,
};

SuccessAlert.propTypes = {
  message: messagePropType,
  closeAlert: PropTypes.func,
};

const InfoAlert = ({ message, closeAlert }) => {
  // This alert is using global styles defined in app/assets/stylesheets/ui_toolbox/_alerts.scss
  return (
    <div className='alert-wrap'>
      <div className='alert alert-info z-depth-1 clearfix' role='alert' aria-label='info alert'>
        <div className='text-content'>
          <i className='fal icon icon-info-circle' />
          <span className='text'>{message}</span>
        </div>
        <button className='close' onClick={closeAlert} aria-label='close info alert'>
          <i className='fal icon-times' />
        </button>
      </div>
    </div>
  );
};

InfoAlert.defaultProps = {
  message: 'Action was successfully executed',
  closeAlert: () => null,
};

InfoAlert.propTypes = {
  message: messagePropType,
  closeAlert: PropTypes.func,
};

const WarningAlert = ({ message, closeAlert }) => {
  // This alert is using global styles defined in app/assets/stylesheets/ui_toolbox/_alerts.scss
  return (
    <div className='alert-wrap'>
      <div
        className='alert alert-warning z-depth-1 clearfix'
        role='alert'
        aria-label='warning alert'
      >
        <div className='text-content'>
          <i className='fal icon icon-exclamation-triangle' />
          <span className='text'>{message}</span>
        </div>
        <button className='close' onClick={closeAlert} aria-label='close warning alert'>
          <i className='fal icon-times' />
        </button>
      </div>
    </div>
  );
};

WarningAlert.defaultProps = {
  message: 'Action was successfully executed',
  closeAlert: () => null,
};

WarningAlert.propTypes = {
  message: messagePropType,
  closeAlert: PropTypes.func,
};

class AlertHandler {
  constructor() {
    this.alertContainer = document.createElement('div');
  }
  unMount = () => {
    ReactDOM.unmountComponentAtNode(this.alertContainer);
  };
  // The behaviour of the `#render` method is a bit counter-productive. To make an alert sticky, you
  // also need to define the `timeToUnmount` parameter so the code looks like this
  // alertHandler.render(ErrorAlert, 'the message', 8000, true);
  // In the method call above, the param `8000` (which corresponds to 8 seconds) will be ignored
  // as the `sticky` param takes over.
  // Ideally, the `#render` method should receive "timeToUnmount" and "sticky" wrapped in an
  // "options" object. However, this is not a critical issue and we can live with it.
  render = (Alert, message, timeToUnmount = 8000, sticky = false) => {
    ReactDOM.render(
      <DevexHighOrderComponent>
        <Alert message={message} closeAlert={this.unMount} />
      </DevexHighOrderComponent>,
      document.body.appendChild(this.alertContainer)
    );
    if (sticky) return;
    setTimeout(this.unMount, timeToUnmount);
  };
}

export { AlertHandler, ErrorAlert, SuccessAlert, InfoAlert, WarningAlert };
